import React, { Component } from 'react';
import './App.css';
import Index from './views/pc/index.js'
import Mobile from './views/mobile/index.js'
// import renderEmpty from 'antd/lib/config-provider/renderEmpty';

class App extends Component {
  constructor(props) {
    super(props);    //传递props给component
    this.state = {
      // show: true
    };
  }

  onWindowResize() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      this.setState({
        show: false
      })
    } else {
      this.setState({
        show: true
      })
    }
  }

  componentDidMount() {
    this.onWindowResize()
    window.addEventListener('resize', this.onWindowResize.bind(this))
  }
  componentWillMount() {
    window.removeEventListener('resize', this.onWindowResize.bind(this))
  }


  render() {
    return (
      <div className="App">
        {this.state.show ?
          <Index /> : <Mobile />}
      </div>
    );
  }
}

export default App;
