import React, {Component} from 'react'
import './style.css'

import Header from './Header.js'
import Product from './Product.js'
import Process from './Process.js'
import Characteristic from './Characteristic.js'
import Advantage from './Advantage.js'
import Client from './Client.js'
import Partner from './Partner.js'
import Footer from './Footer.js'

class Index extends Component {
//初始化组件状态
    constructor(props) {
        super(props);    //传递props给component
        this.state = {};
    }

    render() {
        return (
            <div id="pcPage">
                <Header/>
                <Product/>
                <Characteristic/>
                <Advantage/>
                <Footer/>
            </div>
        );
    }
}

export default Index;