import React, { Component } from 'react'
import Hav from './Nav.js'
import banerchahua from '../../assets/img/chahua.svg'
import { Button, Modal, Input, notification } from 'antd'
import axios from 'axios'

class Header extends Component {

	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			loading: false,
			userName: '',
			userPhone: '',
			userCompany: ''
		};
		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangeCompany = this.handleChangeCompany.bind(this);
		this.handleChangePhone = this.handleChangePhone.bind(this);
	}

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = e => {
		console.log(e);
		if (this.state.userName == '' || this.state.userCompany == '' || this.state.userPhone == '') {
			notification.open({
				message: '不能为空',
				description:
					'抱歉，信息不能为空',
				onClick: () => {
					console.log('Notification Clicked!');
				},
			});
		} else if (!(/^1[3|4|5|7|8]\d{9}$/.test(this.state.userPhone))) {
			notification.open({
				message: '错误',
				description:
					'手机号格式不正确',
			})
		} else {
			setTimeout(() => {
				this.setState({ loading: false, visible: false });
			}, 0);
			const url = '';
			let payload = {};
			payload.name = this.state.userName;
			payload.mobile = this.state.userPhone;
			payload.company = this.state.userCompany;
			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				data: payload,
				url: url,
			};
			//axios(options);
			notification.open({
				message: '完成',
				description:
					'提交完成，稍后服务专员会联系您',
				onClick: () => {
					console.log('Notification Clicked!');
				},
			});
			this.state.userPhone = ''
			this.state.userCompany = ''
			this.state.userName = ''
		}
	};
	handleCancel = e => {
		console.log(e);
		this.setState({
			visible: false,
		});
	};
	handleChangeName(event) {
		this.setState({ userName: event.target.value });
	}

	handleChangeCompany(event) {
		this.setState({ userCompany: event.target.value });
	}

	handleChangePhone(event) {
		this.setState({ userPhone: event.target.value });
	}

	componentDidMount() {
	}

	render() {

		const { visible, loading } = this.state;
		return (
			<div id="header">
				<Hav />
				<Modal
					title="申请试用"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="cancel" onClick={this.handleCancel}>
							取消
                        </Button>,
						<Button key="Submit" type="primary" loading={loading} onClick={this.handleOk}>
							提交
                        </Button>,
					]}
				>
					姓名：<Input value={this.state.userName} onChange={this.handleChangeName} placeholder="请输入您的姓名"
						style={{ marginBottom: '20px' }} allowClear />
					公司：<Input value={this.state.userCompany} onChange={this.handleChangeCompany} placeholder="请输入您的所属公司名称"
						style={{ marginBottom: '20px' }} allowClear />
					手机号：<Input value={this.state.userPhone} onChange={this.handleChangePhone} placeholder="请输入您的手机号"
						style={{ marginBottom: '20px' }} allowClear />
				</Modal>
				<div className="bannerPc content">
					<div style={{ float: "left" }}>
						<div style={{
							marginTop: '120px',
							width: "530px",
							height: "42px",
							fontSize: "42px",
							fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUI',
							color: 'rgba(255,255,255,1)',
							lineHeight: "42px",
							letterSpacing: "2px"
						}}>企业供应链解决方案提供商
                        </div>
						<div style={{
							marginTop: '28px',
							marginBottom: '34px',
							width: '382px',
							height: '20px',
							fontSize: '20px',
							fontFamily: 'PingFangSC-Light',
							fontWeight: '300',
							color: 'rgba(135,150,175,1)',
							lineHeight: '20px'
						}}>Enterprise Supply Chain Solution Provider
                        </div>
						<div style={{
							width: '480px',
							height: '96px',
							fontSize: '16px',
							fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUILight',
							color: 'rgba(255,255,255,1)',
							lineHeight: '32px'
						}}>采购协同系统帮助企业实现采购流程数字化，技术上通过对企业内部 ERP、SRM等系统的对接，
											对外部协同系统如腾讯企点、企业微信 的对接。来助力企业数字化转型，帮助企业降本增效。
                        </div>
						{/* <div style={{ width: '116px', height: '36px', background: 'rgba(231,232,234,1)', borderRadius: '18px', color: 'black', textAlign: 'center', lineHeight: '34px', marginTop: '44px' }}><a href="https://scm.tengwow.com/user/register?userType=company" style={{ color: '#000' }}>了解详情 →</a></div> */}
						{/* <Button style={{ marginTop: '44px' }} shape="round" type="primary"
							onClick={this.showModal}>申请试用</Button> */}

					</div>
					<div style={{ float: "left", marginLeft: '50px', marginTop: '50px' }}>
						<img src={banerchahua} />
					</div>
				</div>
			</div>
		);
	}
}

export default Header;