import React, { Component } from 'react'
import { Row, Col } from 'antd';
class Footer extends Component {
	render() {
		return (
			<div id="footer">
				<div className="content">
					{/* <Row style={{ marginTop: '40px', marginBottom: '40px' }}>
						<Col span={24}>
							<img src={require('../../assets/img/logo_2.png')} alt="img" />
						</Col>
					</Row>
					<div style={{ overflow: 'hidden', marginTop: '60px' }}>
						<div style={{ float: 'left', width: '520px' }}>
							<p style={{ marginBottom: '30px' }}><img style={{ marginRight: '22px' }} alt="img" src={require('../../assets/img/building-3-fill.png')} /><a name="footer"></a>上海市杨浦区政高路77号同和国际大厦B座201-204室</p>
							<p><img style={{ marginRight: '22px' }} src={require('../../assets/img/customer-service-fill.png')} alt="img" />400 1819 160</p>
						</div>
						<div style={{ float: 'left', width: '450px' }}>
							<p style={{ marginBottom: '30px' }}><img style={{ marginRight: '22px' }} src={require('../../assets/img/mail-fill.png')} alt="img" />support@tengwow.com</p>
							<p><img style={{ marginRight: '22px' }} src={require('../../assets/img/attachment-fill.png')} alt="img" />友请链接：易祺采</p>
						</div>
						<div style={{ float: 'left' }}>
							<img style={{ float: 'left' }} src={require('../../assets/img/tengwowcode.png')} alt="img" />
							<span style={{ float: 'left' }}>
								<p style={{ margin: '12px 0px 20px 30px' }}>扫一扫</p>
								<p style={{ margin: '0px 0px 0px 30px' }}>关注微信公众号</p>
							</span>
						</div>
					</div> */}
					<div style={{ marginTop: '45px', marginBottom: '18px', borderTop: '1px solid white', opacity: '0.3' }}></div>
					<div>
						<span style={{ float: 'left' }}> 《隐私权政策》</span>
						<span style={{ float: 'right' }}><a href='http://beian.miit.gov.cn/'>沪ICP备19039761号-1</a></span>
					</div>
				</div>

			</div>
		);
	}
}

export default Footer;