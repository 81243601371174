import React, { Component } from 'react'

class Process extends Component {
	render() {
		return (
			<div id="process">
				<div className="content">
					<div className="pageTitle" style={{ color: 'white' }}><a name="process"></a>协同过程</div>
					<div className="pageTitle2" style={{ color: 'white' }}>Product introduction & easy to pick</div>
					<div style={{ marginTop: '40px', marginBottom: '50px' }}>
						<span className="sameTitle" style={{ color: 'white' }}>采购方</span>
						<span className="sameTitle2" style={{ color: 'white' }}>buyer</span>
					</div>

					<div style={{ textAlign: 'center', overflow: 'hidden' }}>
						<div style={{ width: '80px', float: 'left' }}>
							<img src={require('../../assets/img/bank-card-fill.png')} />
							<p style={{ marginTop: '20px' }}>需求管理</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/store-3-fill.png')} />
							<p style={{ marginTop: '20px' }}>确认供应商</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/album-fill.png')} />
							<p style={{ marginTop: '20px' }}>建立关系</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/send-plane-2-fill.png')} />
							<p style={{ marginTop: '20px' }}>发送订单</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/mail-check-fill.png')} />
							<p style={{ marginTop: '20px' }}>信息确认</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/stack-fill.png')} />
							<p style={{ marginTop: '20px' }}>交易协同</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/inbox-fill.png')} />
							<p style={{ marginTop: '20px' }}>收发货管理</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/money-cny-circle-fill.png')} />
							<p style={{ marginTop: '20px' }}>对账管理</p>
						</div>
					</div>
					<div style={{ height: '1px', opacity: 0.1, background: 'rgba(255,255,255,1)', clear: 'both', marginTop: '50px', marginBottom: '50px' }}></div>
					<div style={{ marginTop: '40px', marginBottom: '50px' }}>
						<span className="sameTitle" style={{ color: 'white' }}>供应商</span>
						<span className="sameTitle2" style={{ color: 'white' }}>supplier</span>
					</div>
					<div style={{ textAlign: 'center', overflow: 'hidden' }}>
						<div style={{ width: '80px', float: 'left' }}>
							<img src={require('../../assets/img/home-5-fill.png')} />
							<p style={{ marginTop: '20px' }}>供应商入驻</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/emotion-2-fill.png')} />
							<p style={{ marginTop: '20px' }}>供应商评估</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/swap-box-fill.png')} />
							<p style={{ marginTop: '20px' }}>供应商分级</p>
						</div>
						<div className="projiantou"></div>
						<div style={{ width: '80px', float: 'left' }}>
							<img className="processImg" src={require('../../assets/img/apps-2-fill.png')} />
							<p style={{ marginTop: '20px' }}>供应商管控</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Process;