import React, { Component } from 'react'

class Client extends Component {
	render() {
		return (
			<div id="client">
				<div className="content">
					<div className="pageTitle"><a name="client"></a>部分客户</div>
					<div className="pageTitle2">Client</div>
					<div className="clientTab">
						<div>
							<img alt="img" src={require('../../assets/imgMobile/clientPhoto1.png')} />
							<p>浙江友联紧固件有限公司</p>
							<span>查看详情 > </span>
							<hr />
						</div>
						<div style={{ margin: "0px 30px" }}>
							<img alt="img" src={require('../../assets/imgMobile/clientPhoto2.png')} />
							<p>迪斯油压工业（昆山）有限公司</p>
							<span>查看详情 > </span>
							<hr />
						</div>
						<div>
							<img alt="img" src={require('../../assets/imgMobile/clientPhoto3.png')} />
							<p>宁波奥德普电梯部件有限公司</p>
							<span>查看详情 > </span>
							<hr />
						</div>
					</div>
					<div className="clientTab">
						<div>
							<img alt="img" src={require('../../assets/imgMobile/clientPhoto4.png')} />
							<p>上海众松电气设备有限公司</p>
							<span>查看详情 > </span>
							<hr />
						</div>
						<div style={{ margin: "0px 30px" }}>
							<img alt="img" src={require('../../assets/imgMobile/clientPhoto5.png')} />
							<p>昆山蓝心自动化设备有限公司</p>
							<span>查看详情 > </span>
							<hr />
						</div>
						<div>
							<img alt="img" src={require('../../assets/imgMobile/clientPhoto6.png')} />
							<p>浙江万龙机械有限公司</p>
							<span>查看详情 > </span>
							<hr />
						</div>
					</div>
					<div className="clientTab">
						<div>
							<img alt="img" src={require('../../assets/imgMobile/clientPhoto7.png')} />
							<p>宁波市鄞州区康发电梯部件有限公司</p>
							<span>查看详情 > </span>
							<hr />
						</div>
						<div style={{ margin: "0px 30px" }}>
							<img alt="img" src={require('../../assets/imgMobile/clientPhoto8.png')} />
							<p>宁波大坦机械有限公司</p>
							<span>查看详情 > </span>
							<hr />
						</div>
						<div>
							<img alt="img" src={require('../../assets/imgMobile/clientPhoto9.png')} />
							<p>奇精机械股份有限公司</p>
							<span>查看详情 > </span>
							<hr />
						</div>
					</div>
				</div>
			</div >
		);
	}
}

export default Client;