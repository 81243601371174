import React, { Component } from 'react'

class Advantage extends Component {
	render() {
		return (
			<div id="advantage">
				<div className="advTitle">
					<div className="content">
						<div className="pageTitle" style={{ marginTop: '100px' }}>优势</div>
						<div className="pageTitle2">Advantage</div>
					</div>
				</div>
				<div className="advContent">
					<div className="content">
						<div className="advConTab">
							<div>
								<img src={require('../../assets/img/funds-fill.png')} />
								<span>提高效率</span>
							</div>
							<span>解决采购和供应商之间在冗长的业务流程中的协同效 率问题，并且提高透明度</span>
						</div>
						<div className="advConTab" style={{ margin: '0px 30px' }}>
							<div>
								<img src={require('../../assets/img/notification-badge-fill.png')} />
								<span>采用IM</span>
							</div>
							<span>采用IM工具作为协同基础，使用门槛低</span>
						</div>
						<div className="advConTab">
							<div>
								<img src={require('../../assets/img/shopping-cart-fill.png')} />
								<span>阳光采购</span>
							</div>
							<span>从招投标，询报价，订单协同到发票付款全程透明， 避免腐败，降低企业风险</span>
						</div>
						<div className="advConTab">
							<div>
								<img src={require('../../assets/img/chat-smile-3-fill.png')} />
								<span>评价体系</span>
							</div>
							<span>提供供应商评价体系数据服务，降低采购方的主要风 险：供货稳定，成本控制，质量稳定</span>
						</div>
						<div className="advConTab" style={{ margin: '0px 30px' }}>
							<div>
								<img src={require('../../assets/img/settings-6-fill.png')} />
								<span>相关系统</span>
							</div>
							<span>企业内外部相关系统的打通，提高系统效率，进一步 提高企业运营效率</span>
						</div>
						<div className="advConTab">
							<div>
								<img src={require('../../assets/img/copper-coin-fill.png')} />
								<span>供应链金融</span>
							</div>
							<span>适当的时候引入供应链金融，为资质好的中小企业缓 解资金周转问题</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Advantage;