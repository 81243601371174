import React, { Component } from 'react'
import banerchahua from '../../assets/img/chahua_2.svg'
class Product extends Component {
	render() {
		return (
			<div id="product">
				<div className="content">
					<div className="pageTitle"><a name="product"></a>产品介绍</div>
					<div className="pageTitle2">Product introduction & easy to pick</div>
					<div style={{ overflow: 'hidden' }}>
						<div style={{ float: "left", marginTop: '50px' }}>
							<img src={banerchahua} />
						</div>
						<div style={{ float: "left", marginLeft: '80px', marginTop: '10px' }}>
							{/* <div style={{ marginTop: '84px', marginBottom: '40px', width: "120px", height: "24px", fontSize: "24px", fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUI', color: 'rgba(33,110,244,1)', lineHeight: "24px" }}>易祺采</div> */}
							<div style={{ width: '577px', height: '136px', fontSize: '14px', color: 'rgba(68,68,68,1)', fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUILight', lineHeight: '34px' }}>
							功能覆盖中小企业供应链的采购收货全过程，包括订单协同、收发货和退货管理、询报价、对账、合同与招投标等。基于业务流程的延伸和提供服务的增加，逐步建立供应商评价体系，为进一步引入供应链金融做好准备。此外，还与ERP、SRM等系统集成对接，帮助企业降本增效，助力企业数字化转型。
							</div>
							<div style={{ fontSize: "14px", width: '116px', height: '36px', border: '1px solid rgba(33,110,244,1)', borderRadius: '18px', color: 'rgba(33,110,244,1)', textAlign: 'center', lineHeight: '34px', marginTop: '44px' }}>了解详情 →</div>
						</div>
					</div>
					<div style={{ height: '1px', background: 'rgba(238,238,238,1)', clear: 'both', marginTop: '65px' }}></div>
					<div style={{ marginTop: '40px', marginBottom: '50px', textAlign: 'center' }}>
						<span className="sameTitle">功能</span>
						<span className="sameTitle2">Features</span>
					</div>
					<div className="features" style={{ textAlign: 'center' }}>
						<div style={{ width: '14.2%', float: 'left' }}>
							<img src={require('../../assets/img/Orion_atom.png')} />
							<p style={{ marginTop: '20px' }}>主数据管理</p>
						</div>
						<div style={{ width: '14.2%', float: 'left' }}>
							<img src={require('../../assets/img/Orion_world-globe.png')} />
							<p style={{ marginTop: '20px' }}>供应商管理</p>
						</div>
						<div style={{ width: '14.2%', float: 'left' }}>
							<img src={require('../../assets/img/Orion_settings-ecommerce.png')} />
							<p style={{ marginTop: '20px' }}>采购管理</p>
						</div>
						<div style={{ width: '14.2%', float: 'left' }}>
							<img src={require('../../assets/img/Orion_document-stack.png')} />
							<p style={{ marginTop: '20px' }}>合同管理</p>
						</div>
						<div style={{ width: '14.2%', float: 'left' }}>
							<img src={require('../../assets/img/Orion_receipt.png')} />
							<p style={{ marginTop: '20px' }}>采购订单协同</p>
						</div>
						<div style={{ width: '14.2%', float: 'left' }}>
							<img src={require('../../assets/img/Orion_open-box.png')} />
							<p style={{ marginTop: '20px' }}>收发货管理</p>
						</div>
						<div style={{ width: '14.2%', float: 'left' }}>
							<img src={require('../../assets/img/Orion_pay.png')} />
							<p style={{ marginTop: '20px' }}>财务结算</p>
						</div>
					</div>

				</div>

			</div>
		);
	}
}

export default Product;