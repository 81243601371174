import React,{Component} from 'react'

class Characteristic extends Component {
    render() {
        return (
            <div id="characteristic">
				<div className="content">
					<div className="pageTitle"><a name="characteristic"></a>系统特点</div>
					<div className="pageTitle2">Product introduction & easy to pick</div>
					<div className="chara">
						<div className="charaTab">
							<section>
								<img src={require('../../assets/img/jishitongxun.png')} />
								<p>即时通讯</p>
							</section>
							<div>
								<img src={require('../../assets/img/jishitongxun_2.png')} />
								<p>一</p>
								<p>即时通讯</p>
								<span>深度集成QQ，企业微信，钉钉等即 时通讯软件大力提高企业间，企业 业务负责人之间的协同和协作</span>
							</div>
						</div>
						<div className="charaTab">
							<section>
								<img src={require('../../assets/img/xianghuguantong.png')} />
								<p>相互贯通</p>
							</section>
							<div>
								<img src={require('../../assets/img/xianghuguantong_2.png')} />
								<p>一</p>
								<p>相互贯通</p>
								<span>和其他企业内部系统的打通: 如ERP，PLM等，降低用户使用成本</span>
							</div>
						</div>
						<div className="charaTab">
							<section>
								<img src={require('../../assets/img/yewuliucheng.png')} />
								<p>业务流程</p>
							</section>
							<div>
								<img src={require('../../assets/img/yewuliucheng_2.png')} />
								<p>一</p>
								<p>业务流程</p>
								<span>基于区块链的可信业务流程数据：为监管和保理机构供应链金融相关服务做好准备</span>
							</div>
						</div>
						<div className="charaTab">
							<section>
								<img src={require('../../assets/img/shangyezhineng.png')} />
								<p>商业智能</p>
							</section>
							<div>
								<img src={require('../../assets/img/shangyezhineng_2.png')} />
								<p>一</p>
								<p>商业智能</p>
								<span>基于大数据的商业智能：根据所有采购方和销售方的交易数据和协同数据，发掘数据所隐含的价值</span>
							</div>
						</div>
						<div className="charaTab">
							<section>
								<img src={require('../../assets/img/hunhebushu.png')} />
								<p>混合部署</p>
							</section>
							<div>
								<img src={require('../../assets/img/hunhebushu_2.png')} />
								<p>一</p>
								<p>混合部署</p>
								<span>公有云和私有云的混合部署，满足不同客户的需求</span>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

export default Characteristic;