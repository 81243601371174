import React, { Component } from 'react'
import './index.css'
import { Row, Col, Button, Drawer, Modal, Input, notification } from 'antd'
import axios from 'axios'
// import Header from './Header.js'

import logo from './../../assets/imgMobile/logo_top@2x.png'
import topRight from './../../assets/imgMobile/fenlei_icon@2x.png'
import proDetail from './../../assets/imgMobile/chanpinjieshao@2x.png'

// 采购
import narrow from './../../assets/imgMobile/xietong_narrow@2x.png'
import caigou01 from './../../assets/imgMobile/caigouuqiu_icon@2x.png'
import caigou02 from './../../assets/imgMobile/querengongyingshang_icon@2x.png'
import caigou03 from './../../assets/imgMobile/jinliguanxi_icon@2x.png'
import caigou04 from './../../assets/imgMobile/fasongdingdan_icon@2x.png'
import caigou05 from './../../assets/imgMobile/xinxiqueren_icon@2x.png'
import caigou06 from './../../assets/imgMobile/jiaoyixietong_icon@2x.png'
import caigou07 from './../../assets/imgMobile/shoufahuo_icon@2x.png'
import caigou08 from './../../assets/imgMobile/duizhang_icon@2x.png'

// 供应
import gongying01 from './../../assets/imgMobile/store-3-fill.png'
import gongying02 from './../../assets/imgMobile/pinggu_icon@2x.png'
import gongying03 from './../../assets/imgMobile/fenji_icon@2x.png'
import gongying04 from './../../assets/imgMobile/guankong_icon@2x.png'

import tedian01 from './../../assets/imgMobile/tedian_01_icon@2x.png'
import tedian02 from './../../assets/imgMobile/tedian_02_icon@2x.png'
import tedian03 from './../../assets/imgMobile/tedian_03_icon@2x.png'
import tedian04 from './../../assets/imgMobile/tedian_04_icon@2x.png'
import tedian05 from './../../assets/imgMobile/tedian_05_icon@2x.png'
import phoneIcon from './../../assets/imgMobile/phone_icon@2x.png'
import addressIcon from './../../assets/imgMobile/address_icon@2x.png'
import linkIcon from './../../assets/imgMobile/link_icon@2x.png'
import emailIcon from './../../assets/imgMobile/email_icon@2x.png'
import wechatPng from './../../assets/imgMobile/tengwowwechat@2x.png'
import youshi1 from './../../assets/imgMobile/youshi_01_icon@2x.png'
import youshi2 from './../../assets/imgMobile/youshi_02_icon@2x.png'
import youshi3 from './../../assets/imgMobile/youshi_03_icon@2x.png'
import youshi4 from './../../assets/imgMobile/youshi_04_icon@2x.png'
import youshi5 from './../../assets/imgMobile/youshi_05_icon@2x.png'
import youshi6 from './../../assets/imgMobile/youshi_06_icon@2x.png'
import chinajey from './../../assets/imgMobile/chinajey.png'
import tencent_Clound from './../../assets/imgMobile/tencent_Clound.png'
import tencent_icon from './../../assets/imgMobile/tencent_icon.png'

// 合作公司
import clientPhoto1 from './../../assets/imgMobile/clientPhoto1.png'
import clientPhoto2 from './../../assets/imgMobile/clientPhoto2.png'
import clientPhoto3 from './../../assets/imgMobile/clientPhoto3.png'
import clientPhoto4 from './../../assets/imgMobile/clientPhoto4.png'
import clientPhoto5 from './../../assets/imgMobile/clientPhoto5.png'
import clientPhoto6 from './../../assets/imgMobile/clientPhoto6.png'
import clientPhoto7 from './../../assets/imgMobile/clientPhoto7.png'
import clientPhoto8 from './../../assets/imgMobile/clientPhoto8.png'
import clientPhoto9 from './../../assets/imgMobile/clientPhoto9.png'


class Index extends Component {
    //初始化组件状态
    constructor(props) {
        super(props);    //传递props给component
        this.showCaigou = this.showCaigou.bind(this)
        this.showGongying = this.showGongying.bind(this)

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);

        this.state = {
            show: 1,
            visible: false,
            type1: 'primary',
            type2: '',
            visible2: false,
            loading: false,
            userName: '',
            userPhone: '',
            userCompany: ''
        };
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?99785c33eb4f6d0dc9a6e4bb2ca4195f";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
    }
    showCaigou() {
        this.setState({ show: 1, type1: 'primary', type2: '' })
    }
    showGongying() {
        this.setState({ show: 2, type1: '', type2: 'primary' })
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible2: false,
        });
    };

    showModal = () => {
        this.setState({
            visible2: true,
        });
    };

    handleOk = e => {
        console.log(e);
        if (this.state.userName == '' || this.state.userCompany == '' || this.state.userPhone == '') {
            notification.open({
                message: '不能为空',
                description:
                    '抱歉，信息不能为空',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        } else if (!(/^1[3|4|5|7|8]\d{9}$/.test(this.state.userPhone))) {
            notification.open({
                message: '错误',
                description:
                    '手机号格式不正确',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            console.log(this.state.userPhone)
        } else {
            setTimeout(() => {
                this.setState({ loading: false, visible2: false });
            }, 0);
            const url = 'https://feedback.www.tengwow.com/free-trial/';
            let payload = {};
            payload.name = this.state.userName;
            payload.mobile = this.state.userPhone;
            payload.company = this.state.userCompany;
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: payload,
                url: url,
            };
            axios(options);
            notification.open({
                message: '完成',
                description:
                    '提交完成，稍后服务专员会联系您',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            this.state.userPhone = ''
            this.state.userCompany = ''
            this.state.userName = ''
        }
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible2: false,
        });
    };
    handleChangeName(event) {
        this.setState({ userName: event.target.value });
    }
    handleChangeCompany(event) {
        this.setState({ userCompany: event.target.value });
    }
    handleChangePhone(event) {
        this.setState({ userPhone: event.target.value });
    }

    render() {
        const { visible, loading } = this.state;
        return (
            <div id="MobilePage">
                <div className="top">
                    {/* 菜单 */}
                    <Drawer
                        title="首页"
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                    >
                        <p>产品</p>
                        <p>系统特点</p>
                        <p>客户</p>
                        <p>关于我们</p>
                        <hr />
                        {/* <p><a style={{ color: 'rgba(0,0,0,.65)' }} target="target" href="https://scm.tengwow.com">登录</a></p>
                        <p><a style={{ color: 'rgba(0,0,0,.65)' }} target="target" href="https://scm.tengwow.com/user/register?userType=company">免费注册</a></p> */}
                    </Drawer>
                    {/* 顶部 */}
                    <Row>
                        {/* <Col span={8}><img className="logo" src={logo} alt="img" /></Col> */}
                        <Col span={8} align="center"><span className="homeTitle">首页</span></Col>
                        <Col span={8}><img className="topRight" src={topRight} onClick={this.showDrawer} /></Col>
                    </Row>
                    {/* banner */}
                    <div className="banner">
                        <p className="banner-p mt30">企业供应链</p>
                        <p className="banner-p">解决方案提供商</p>
                        {/* <Button style={{ marginTop: '24px', marginLeft: '20px', fontSize: '14px' }} shape="round" type="primary" onClick={this.showModal}>申请试用</Button> */}
                        <a href=""><div style={{float: 'right',marginTop: '28px',marginRight: '28px'}}><img style={{ verticalAlign: 'sub', marginRight: '10px' }} src={require('../../assets/imgMobile/phone-mobile.png')} /><span style={{fontFamily: "PingFangSC-Regular,Microsoft YaHei",color: '#fff'}}></span></div>
                        </a>
                    </div>
                </div>
                <Modal
                    title="申请试用"
                    visible={this.state.visible2}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="cancel" onClick={this.handleCancel}>
                            取消
						</Button>,
                        <Button key="Submit" type="primary" loading={loading} onClick={this.handleOk}>
                            提交
						</Button>,
                    ]}
                >
                    您的姓名：<Input value={this.state.userName} onChange={this.handleChangeName} placeholder="请输入您的姓名" style={{ marginBottom: '20px' }} allowClear />
                    您的公司：<Input value={this.state.userCompany} onChange={this.handleChangeCompany} placeholder="请输入您的所属公司名称" style={{ marginBottom: '20px' }} allowClear />
                    手机号：<Input value={this.state.userPhone} onChange={this.handleChangePhone} placeholder="请输入您的手机号" style={{ marginBottom: '20px' }} allowClear />
                </Modal>
                {/* 产品介绍 */}
                <div>
                    <p className="h2_cn">产品介绍</p>
                    <p className="h2_en">Product introduction & easy to pick</p>
                    <p className="product_name"></p>
                    <img className="proDetail" src={proDetail} />
                    <Button className="showMore" shape="round">了解详情 →</Button>
                </div>
                {/* 协同过程 */}
                <div className="cooperate">
                    <p className="p1">协同过程</p>
                    <p className="p2">Product introduction & easy to pick</p>
                    <p className="button-home">
                        <Button className="btn-l-20" type={this.state.type1} onClick={this.showCaigou} shape="round">采购方</Button>
                        <Button className="btn-l-20" shape="round" type={this.state.type2} onClick={this.showGongying}>供应商</Button>
                    </p>

                    {this.state.show == 1 ? <div className="swiper-box">
                        <div>
                            <img className="swiper-box-img" src={caigou01} />
                            <p>需求管理</p>
                        </div>
                        <img className="narrow" src={narrow} />
                        <div>
                            <img className="swiper-box-img" src={gongying01} />
                            <p>确认供应商</p>
                        </div>
                        <img className="narrow" src={narrow} />
                        <div>
                            <img className="swiper-box-img" src={caigou03} />
                            <p>建立关系</p>
                        </div>
                        <img className="narrow" src={narrow} />
                        <div>
                            <img className="swiper-box-img" src={caigou04} />
                            <p>发送订单</p>
                        </div>
                        <img className="narrow" src={narrow} />
                        <div>
                            <img className="swiper-box-img" src={caigou05} />
                            <p>信息确认</p>
                        </div>
                        <img className="narrow" src={narrow} />
                        <div>
                            <img className="swiper-box-img" src={caigou06} />
                            <p>交易协同</p>
                        </div>
                        <img className="narrow" src={narrow} />
                        <div>
                            <img className="swiper-box-img" src={caigou07} />
                            <p>收发货管理</p>
                        </div>
                        <img className="narrow" src={narrow} />
                        <div>
                            <img className="swiper-box-img" src={caigou08} />
                            <p>对账管理</p>
                        </div>
                    </div> : <div className="swiper-box">
                            <div>
                                <img className="swiper-box-img" src={caigou02} />
                                <p>供应商入驻</p>
                            </div>
                            <img className="narrow" src={narrow} /><div>
                                <img className="swiper-box-img" src={gongying02} />
                                <p>供应商评估</p>
                            </div>
                            <img className="narrow" src={narrow} /><div>
                                <img className="swiper-box-img" src={gongying03} />
                                <p>供应商分级</p>
                            </div>
                            <img className="narrow" src={narrow} /><div>
                                <img className="swiper-box-img" src={gongying04} />
                                <p>供应商管控</p>
                            </div>
                        </div>}
                </div>

                {/* 系统特点 */}
                <div className="xietong">
                    <p className="p1">系统特点</p>
                    <p className="p2">Product introduction & easy to pick</p>
                    <Row className="mt20">
                        <Col span={8} align="center" className="xitong_div">
                            <img className="tedian_icon" src={tedian01} />
                            <div className="line_icon" />
                            <p className="tedian_title">即时通讯</p>
                        </Col>
                        <Col span={8} align="center" className="xitong_div">
                            <img className="tedian_icon" src={tedian02} />
                            <div className="line_icon" />
                            <p className="tedian_title">相互贯通</p>
                        </Col>
                        <Col span={8} align="center" className="xitong_div">
                            <img className="tedian_icon" src={tedian03} />
                            <div className="line_icon" />
                            <p className="tedian_title">业务流程</p>
                        </Col>
                    </Row>
                    <Row className="mt10" gutter={32}>
                        <Col span={8} offset={4} align="center" className="xitong_div">
                            <img className="tedian_icon" src={tedian04} />
                            <div className="line_icon" />
                            <p className="tedian_title">商业智能</p>
                        </Col>
                        <Col span={8} align="center" className="xitong_div">
                            <img className="tedian_icon" src={tedian05} />
                            <div className="line_icon" />
                            <p className="tedian_title">混合部署</p>
                        </Col>
                    </Row>
                </div>
                {/* 优势 */}
                <div className="gift">
                    <p className="p1">优势</p>
                    <p className="p2">Advantage</p>
                    <Row className="mt20">
                        <Col span={22} offset={1} className="gift_item">
                            <Row>
                                <img src={youshi1} alt="gift_item" />
                            </Row>
                            <Row className="gift_item_div2">
                                <p className="gift_item_title">提高效率</p>
                                <p className="gift_item_content">解决采购和供应商之间在冗长的业务流程中的 协同效率问题，并且提高透明度</p>
                            </Row>
                        </Col>
                        <Col span={22} offset={1} className="gift_item">
                            <Row>
                                <img src={youshi2} alt="gift_item" />
                            </Row>
                            <Row className="gift_item_div2">
                                <p className="gift_item_title">采用IM</p>
                                <p className="gift_item_content">采用IM工具作为协同基础，使用门槛低</p>
                            </Row>
                        </Col>
                        <Col span={22} offset={1} className="gift_item">
                            <Row>
                                <img src={youshi3} alt="gift_item" />
                            </Row>
                            <Row className="gift_item_div2">
                                <p className="gift_item_title">阳光采购</p>
                                <p className="gift_item_content">从招投标，询报价，订单协同到发票付款全程 透明，避免腐败，降低企业风险</p>
                            </Row>
                        </Col>
                        <Col span={22} offset={1} className="gift_item">
                            <Row>
                                <img src={youshi4} alt="gift_item" />
                            </Row>
                            <Row className="gift_item_div2">
                                <p className="gift_item_title">评价体系</p>
                                <p className="gift_item_content">提供供应商评价体系数据服务，降低采购方的 主要风险：供货稳定，成本控制，质量稳定</p>
                            </Row>
                        </Col>
                        <Col span={22} offset={1} className="gift_item">
                            <Row>
                                <img src={youshi5} alt="gift_item" />
                            </Row>
                            <Row className="gift_item_div2">
                                <p className="gift_item_title">相关系统</p>
                                <p className="gift_item_content">企业内外部相关系统的打通，提高系统效率， 进一步提高企业运营效率</p>
                            </Row>
                        </Col>
                        <Col span={22} offset={1} className="gift_item">
                            <Row>
                                <img src={youshi6} alt="gift_item" />
                            </Row>
                            <Row className="gift_item_div2">
                                <p className="gift_item_title">供应链金融</p>
                                <p className="gift_item_content">适当的时候引入供应链金融，为资质好的中小 企业缓解资金周转问题</p>
                            </Row>
                        </Col>
                    </Row>
                </div>
                {/* 客户 */}
                
                {/* 合作伙伴 */}
                {/* <div className="pageTitle"><a name="product"></a>公司介绍</div>
                <div className="pageTitle2">Company Introduction</div>
                <p style={{ width: '70% ', textAlign: 'center', margin: '30px auto', fontSize: '14px', color: 'rgba(68,68,68,1)', fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUILight', lineHeight: '34px' }}>腾来雾往（上海）科技有限公司是一家专注于企业供应链解决方案的提供商，以采购协同系统帮助企业实现采购流程数字化，遴选出行业内优质的供应商。在技术上通过对企业内部ERP、SRM等系统的对接，助力企业数字化转型，帮助企业降本增效。</p>

                <p style={{ width: '70% ', textAlign: 'center', margin: '30px auto', fontSize: '14px', color: 'rgba(68,68,68,1)', fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUILight', lineHeight: '34px' }}>公司旗下产品易祺采，主要是覆盖中小企业的采购收货全流程，流程向前衍生到采购合同，采购询价，销售报价；向流程后端衍生开票和付款流程。基于延生的业务流程覆盖和服务客户的增加，逐步建立供应商评价体系。并为进一步引入供应链金融做好平台和数据准备。</p>

                <p style={{ width: '70% ', textAlign: 'center', margin: '30px auto', fontSize: '14px', color: 'rgba(68,68,68,1)', fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUILight', lineHeight: '34px' }}>目前所服务的行业主要包括：紧固件贸易，五金/建材贸易，装备制造和小型制造行业。</p> */}

                {/* footer */}

                <p className="copyright">版权信息</p>
            </div >
        );
    }
}

export default Index;