import React, { Component } from 'react'

class Partner extends Component {
	render() {
		return (
			<div id="partner">
				<div className="content">
					<div className="pageTitle">合作伙伴</div>
					<div className="pageTitle2">Partner</div>
					<div style={{ marginTop: '50px' }}>
						<img style={{ width: '200px' }} src={require('../../assets/img/tengxunyun.png')} alt="img" />
						<img style={{ width: '200px' }} src={require('../../assets/img/tengxunqidian.png')} alt="img" />
						<img style={{ width: '200px' }} src={require('../../assets/img/zhongzhijie.png')} alt="img" />
					</div>

					<div className="pageTitle"><a name="product"></a>公司介绍</div>
					<div className="pageTitle2">Company Introduction</div>
					<p style={{ width: '70% ', textAlign: 'center', margin: '30px auto', fontSize: '14px', color: 'rgba(68,68,68,1)', fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUILight', lineHeight: '34px' }}>腾来雾往（上海）科技有限公司是一家专注于企业供应链解决方案的提供商，以采购协同系统帮助企业实现采购流程数字化，遴选出行业内优质的供应商。在技术上通过对企业内部ERP、SRM等系统的对接，助力企业数字化转型，帮助企业降本增效。</p>

					<p style={{ width: '70% ', textAlign: 'center', margin: '30px auto', fontSize: '14px', color: 'rgba(68,68,68,1)', fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUILight', lineHeight: '34px' }}>公司旗下产品易祺采，主要是覆盖中小企业的采购收货全流程，流程向前衍生到采购合同，采购询价，销售报价；向流程后端衍生开票和付款流程。基于延生的业务流程覆盖和服务客户的增加，逐步建立供应商评价体系。并为进一步引入供应链金融做好平台和数据准备。</p>

					<p style={{ width: '70% ', textAlign: 'center', margin: '30px auto', fontSize: '14px', color: 'rgba(68,68,68,1)', fontFamily: 'PingFangSC-Regular,MicrosoftYaHeiUILight', lineHeight: '34px' }}>目前所服务的行业主要包括：紧固件贸易，五金/建材贸易，装备制造和小型制造行业。</p>
				</div>
			</div>
		)
	}
}

export default Partner;