import React, { Component } from 'react'
import { Router, Route, Link } from 'react-router'
import './Nav.css'

class Nav extends Component {
	scrollToAnchor = (anchorName) => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName);
			// 如果对应id的锚点存在，就跳转到锚点
			if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
		}
	}
	render() {
		return (
			<div id="nav">
				<div className="content">
					{/* <div className="logo"></div> */}
					<ul className="menu" style={{ paddingLeft: '78px' }}>
						<li className="hbg">首页</li>
						<li><a onClick={() => this.scrollToAnchor('product')} style={{ color: '#fff' }} className="hbg">产品</a></li>
						<li><a onClick={() => this.scrollToAnchor('characteristic')} style={{ color: '#fff' }} className="hbg">系统特点</a></li>
						<li><a onClick={() => this.scrollToAnchor('client')} style={{ color: '#fff' }} className="hbg">客户</a></li>
						<li><a onClick={() => this.scrollToAnchor('footer')} style={{ color: '#fff' }} className="hbg">关于我们</a></li>
						
					</ul>
					{/* <div style={{ float: 'right' }}>
						<div>
							<img style={{ verticalAlign: 'sub', marginRight: '10px' }} src={require('../../assets/img/phone-pc.png')} />
							<span style={{ fontSize: '24px', fontFamily: "PingFangSC-Regular,Microsoft YaHei" }}>400-181-9160</span>
						</div>
						<div style={{ fontSize: '16px', fontFamily: "PingFangSC-Regular,Microsoft YaHei" }}>工作日 9：30-18：30</div>
					</div> */}
					{/* <ul className="menu" style={{ float: 'right' }}>
						<li><a style={{ color: '#fff' }} target="target" href="https://scm.tengwow.com" className="hbg">登录</a></li>
						<li className="lastli"><a style={{ color: '#fff' }} href="https://scm.tengwow.com/user/register?userType=company" className="hbg">免费注册</a></li>
						<li>|</li>
					</ul> */}
				</div>
			</div >
		);
	}
}

export default Nav;